import React from "react"
import { Layout, Parallax } from "@components/layout"
import { Breadcrumbs, AnimatedLink } from "@components/shared"

import {
  page__wrapper,
} from "./styles/page.module.scss"

import {
  tags__wave,
  tags__header,
  tags__wrapper,
  tags__item,
  tags__item__counter,
} from "./styles/tags.module.scss"

const Wave = () => {
  return (
    <div className={tags__wave}>
      <svg viewBox="0 0 1200.88 400" width="100%" height="100%">
        <path
          d="M1200,400c-134.35-137.81-310-62.19-769.68-15.42S0,0,0,0V400Z"
          transform="translate(0.88)"
          fill="#fff"
        />
      </svg>
    </div>
  )
}



const SingleTag = ({ slug, name, count, max }) => {
  const fontSize = 14 + (10 * count) / max
  const fontWeight = 300 + (500 * count) / max
  return (
    <AnimatedLink
      className={tags__item}
      to={`/tags/${slug}`}
      style={{ fontSize, fontWeight }}
    >
      #{name}
      <span className={tags__item__counter}>{count}</span>
    </AnimatedLink>
  )
}

const Tags = ({ pageContext }) => {
  const { page, tags } = pageContext

  return (
    <Layout {...page}>
      <div className={tags__header}>
        <Parallax height={400} image={page.featuredImage}
          title={`${tags.length} - obecna ilość programistycznych tagów tematycznych`}
          subtitle={'znajdź temat, który Cię interesuje'}
        >
          <Wave />
        </Parallax>
      </div>

      <div className={page__wrapper}>
        <Breadcrumbs
          elements={[
            {
              label: page.title,
              url: `/${page.slug}/`,
            },
          ]}
        />
        <div className={tags}>
          <div className={tags__wrapper}>
            {tags.map((el, k) => (
              <SingleTag {...el} key={k} max={tags[0].count} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Tags


// const reviews = [
//   {
//     author: "Mateusz",
//     content:
//       "Ciekawe zadania, dzięki którym można utrwalić wiedzę, a przy tym poznać coś nowego ze świata JSa i Reacta",
//   },
//   {
//     author: "Monika",
//     content:
//       "Ćwiczę z Przemkiem już jakiś czas zadania i czuję, że dopiero teraz zaczynam uczyć się dobrze kodować",
//   },
//   {
//     author: "Krzysztof",
//     content:
//       "Fajna inicjatywa 💪🏻 i ogromna ilość zadań. No to biorę się za kodowanie :)",
//   },
//   {
//     author: "Rafał",
//     content:
//       "Ponad 3 lata jestem Javascript Devem, a do niektórych zadań, żeby podejść to musiałbym porządnie się zastanowić jak je zrobić. Świetny pomysł i wykonanie!",
//   },
// ]

// const ReviewSlide = ({ author, content }) => {
//   return (
//     <div>
//       <h3>{author}</h3>
//       <p>{content}</p>
//     </div>
//   )
// }